@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Prompt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  background-color: #f9fafc;
  position: relative;
}

.user-header {
  width: 100%;
  height: 200px;
  filter: contrast(130%) brightness(100%);
  background: linear-gradient(
      160deg,
      rgba(63, 45, 241, 0.7),
      rgba(41, 64, 218, 0.44),
      rgba(43, 0, 150, 0.01)
    ),
    url(/src/noise.svg);
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Padding top */
.custom-scrollbar {
  padding: 10px;
}

.nav-button {
  background: rgba(139, 92, 246, 0.2);
}
